//& ------------------------------ Quicksand -----------------------------------

@font-face {
	font-family: "quicksand-light";
	src: url("/assets/fonts/Quicksand/Quicksand-Light.ttf");
}

@font-face {
	font-family: "quicksand-regular";
	src: url("/assets/fonts/Quicksand/Quicksand-Regular.ttf");
}

@font-face {
	font-family: "quicksand-medium";
	src: url("/assets/fonts/Quicksand/Quicksand-Medium.ttf");
}

@font-face {
	font-family: "quicksand-semibold";
	src: url("/assets/fonts/Quicksand/Quicksand-SemiBold.ttf");
}

@font-face {
	font-family: "quicksand-bold";
	src: url("/assets/fonts/Quicksand/Quicksand-Bold.ttf");
}

//& ------------------------------ Raleway -----------------------------------

@font-face {
	font-family: "raleway-light";
	src: url("/assets/fonts/Raleway/Raleway-Light.ttf");
}

@font-face {
	font-family: "raleway-regular";
	src: url("/assets/fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
	font-family: "raleway-medium";
	src: url("/assets/fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
	font-family: "raleway-semibold";
	src: url("/assets/fonts/Raleway/Raleway-SemiBold.ttf");
}

@font-face {
	font-family: "raleway-bold";
	src: url("/assets/fonts/Raleway/Raleway-Bold.ttf");
}
