.scrollToTop-container {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 999;
  transform: rotate(90deg);

  .scrollToTopBtn {
    background-color: transparent;

    .icon {
      width: 30px;
      height: 30px;
      transform: rotate(-90deg);
      transition: all ease-in-out 0.3s;
      background-color: $c-corail;
      border-radius: 50%;
      border: $c-corail 1px solid;
      padding: 0.4rem;
      fill: $c-cream;

      &:hover {
        background-color: $c-cream;
        fill: $c-corail;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .scrollToTop-container {
    bottom: 20px;
    right: 7px;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .scrollToTop-container {
    bottom: 30px;
    right: 10px;

    .scrollToTopBtn {
      .icon {
        width: 40px;
        height: 40px;
        padding: 0.5rem;
      }
    }
  }
}
