.contact {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contact-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/assets/images/WEBP/contact-bg-mobile.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    padding: 1rem 0;

    .title {
      font: {
        size: 1.9rem;
        family: $f-raleway-regular;
      }
      line-height: 2.5rem;
      text-transform: uppercase;
      text-align: center;
      color: $c-white;
      margin: 2rem 0;
    }

    .form {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .input-container {
        width: 100%;
        height: 60px;
      }

      .textarea-content {
        width: 100%;
        height: 250px;
      }

      .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: none;
        margin-bottom: 0.3rem;
        padding: 0.5rem;
        font-family: $f-quicksand-regular;
        font-size: 1rem;

        &::placeholder {
          font-size: 1rem;
          color: lighten($c-black, 30%);
        }
      }

      .textarea {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: none;
        min-height: 230px;
        max-height: 230px;
        overflow-y: scroll;
        outline: none;
        resize: none;
        margin-bottom: 0.3rem;
        padding: 0.5rem;
        font-size: 1rem;
        font-family: $f-quicksand-regular;

        &::placeholder {
          font-size: 1rem;
          color: lighten($c-black, 30%);
        }
      }

      .error-message {
        color: darken($c-corail, 10%);
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 0.9rem;
      }

      .button {
        margin: 2rem 0;
        font: {
          size: 1.1rem;
          family: $f-raleway-medium;
        }
        color: $c-white;
        background-color: transparent;
        border: solid 1px $c-white;
        border-radius: 50px;
        padding: 1rem 2rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $c-brown;
          border: solid 1px $c-brown;
        }
      }
    }

    .confirmation-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-family: $f-raleway-medium;
      color: $c-black;
      background-color: #ffffff7d;
      text-align: center;
      line-height: 1.5rem;
      padding: 2rem 0.5rem;
      margin: 0 1rem;
    }
  }

  .map-container {
    width: 100%;
    height: 300px;
    z-index: -1;

    .map {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 414px) {
  .contact {
    .contact-content {
      .form {
        width: 70%;
      }

      .confirmation-message {
        padding: 2rem 1rem;
        margin: 1rem;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .contact {
    .contact-content {
      padding: 2rem 0;

      .title {
        width: 80%;
        font-size: 2.2rem;
        line-height: 2.7rem;
      }

      .form {
        width: 60%;
      }
    }
  }
}

@media (min-width: 899px) {
  .contact {
    .contact-content {
      .form {
        width: 50%;
      }

      .confirmation-message {
        padding: 3rem 7rem;
        margin: 1rem;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .contact {
    .contact-content {
      background-image: url('/assets/images/SVG/HandOnWaistWhite1199.svg'),
        url('/assets/images/SVG/HandOnShoulderWhite1199.svg'), url('/assets/images/WEBP/contact-bg.webp');
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;

      .title {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }

      .form {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .input-container {
          height: 75px;
        }

        .textarea-content {
          height: auto;
        }

        .input {
          font-size: 1.2rem;
          line-height: 1.7rem;

          &::placeholder {
            font-size: 1.2rem;
          }
        }

        .textarea {
          font-size: 1.2rem;
          line-height: 1.7rem;

          &::placeholder {
            font-size: 1.2rem;
          }
        }

        .error-message {
          font-size: 1.2rem;
        }

        .button {
          font-size: 1.3rem;
          margin: 3rem 0;
          padding: 1.2rem 3.5rem;
        }
      }

      .confirmation-message {
        font-size: 1.2rem;
        line-height: 1.7rem;
        padding: 3rem 7rem;
        margin: 2rem;
      }
    }
  }
}

@media (min-width: 1399px) {
  .contact {
    .contact-content {
      background-image: url('/assets/images/SVG/HandOnWaistWhite1399.svg'),
        url('/assets/images/SVG/HandOnShoulderWhite1399.svg'), url('/assets/images/WEBP/contact-bg.webp');
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;

      .title {
        font-size: 3rem;
        line-height: 4rem;
      }

      .form {
        width: 35%;

        .input-container {
          height: 80px;
        }
      }

      .confirmation-message {
        margin: 7rem auto;
        padding: 7rem;
      }
    }

    .map-container {
      height: 400px;
    }
  }
}

@media (min-width: 1599px) {
  .contact {
    .contact-content {
      .title {
        font-size: 4rem;
        line-height: 5rem;
      }
    }
  }
}
