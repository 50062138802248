.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: {
    image: url('/assets/images/WEBP/hero-bg-mobile.webp');
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icons-container {
      .icon {
        width: 30px;
        transition: all 0.3s ease-in-out;
        fill: $c-cream;

        &:hover {
          fill: $c-corail;
          transform: translateY(-3px);
        }
      }

      *:not(:last-child) {
        margin-right: 0.2rem;
      }
    }

    .logo-container {
      display: none;
    }

    .google-container {
      width: 150px;
      display: flex;
      justify-content: flex-end;

      .google {
        width: 100%;
      }
    }
  }

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-light;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-cream;
    span {
      display: block;
    }
  }

  .button {
    font: {
      size: 1.1rem;
      family: $f-raleway-regular;
    }
    color: $c-cream;
    background-color: transparent;
    border: solid 1px $c-cream;
    border-radius: 50px;
    padding: 1rem 2rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $c-corail;
      border: solid 1px $c-corail;
    }
  }
}

@media (min-width: 414px) {
  .hero {
    .header {
      .icons-container {
        .icon {
          width: 40px;
        }
      }

      .google-container {
        width: 200px;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .hero {
    .header {
      .icons-container {
        width: 200px;
      }
    }

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}

@media (min-width: 899px) {
  .hero {
    .title {
      font-size: 3.5rem;
      line-height: 5rem;
    }
  }
}

@media (min-width: 1024px) {
  .hero {
    .header {
      .icons-container {
        width: 200px;
      }
    }

    .logo-container-mobile {
      width: 150px;
      height: 150px;

      .logo-mobile {
        width: 100%;
        height: 100%;
      }
    }

    .button {
      font-size: 1.5rem;
      padding: 1.5rem 3rem;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .hero {
    padding: 1.5rem;
    background: {
      image: url('/assets/images/WEBP/hero-bg.webp');
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
    box-shadow: 0px 0px 10px $c-black;
    z-index: 10;

    .header {
      align-items: flex-start;

      .logo-container {
        display: inline;

        .logo {
          width: 140px;
          height: 140px;
        }
      }
    }

    .logo-container-mobile {
      display: none;
    }
  }
}

@media (min-width: 1399px) {
  .hero {
    .header {
      .logo-container {
        .logo {
          width: 180px;
          height: 180px;
        }
      }
    }

    .title {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
}

@media (min-width: 1920px) {
  .hero {
    .title {
      font-size: 5rem;
      line-height: 7rem;
    }
  }
}
