.results {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $c-brown;
  padding: 1rem 0;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-regular;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-white;
    margin: 2rem 0;
  }

  .more-results {
    display: flex;
    align-items: center;
    color: $c-white;
    font-size: 1rem;
    margin: 3rem 0 1rem 0;

    .icon {
      fill: $c-cream;
      transition: all 0.3s ease-in-out;
      margin: 0.2rem 0 0 0.2rem;

      &:hover {
        fill: $c-black;
        transform: translateY(-3px);
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .results {
    padding: 2rem 0;

    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .results {
    padding: 3rem 1rem;
    box-shadow: 0px 0px 10px $c-black;
    z-index: 10;

    .title {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    .more-results {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}

@media (min-width: 1399px) {
  .results {
    .title {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}

@media (min-width: 1599px) {
  .results {
    .title {
      font-size: 4rem;
      line-height: 5rem;
    }

    .more-results {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
}
