$c-white: #ffffff;
$c-cream: #faf2e9;
$c-beige: #edb89e;
$c-corail: #e6726a;
$c-brown: #c07750;
$c-black: #000000;

$f-quicksand-light: 'quicksand-light';
$f-quicksand-regular: 'quicksand-regular';
$f-quicksand-medium: 'quicksand-medium';
$f-quicksand-semibold: 'quicksand-semibold';
$f-quicksand-bold: 'quicksand-bold';

$f-raleway-light: 'raleway-light';
$f-raleway-regular: 'raleway-regular';
$f-raleway-medium: 'raleway-medium';
$f-raleway-semibold: 'raleway-semibold';
$f-raleway-bold: 'raleway-bold';
