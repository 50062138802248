.services {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: $c-white;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-regular;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-brown;
    margin: 2rem 0;
  }

  .service {
    width: 100%;
    margin: 1rem 0;
    padding: 1.5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-cream;
    border-radius: 5px;

    .service-description {
      display: flex;
      flex-direction: column;

      .service-title {
        color: $c-brown;
        font: {
          size: 1.5rem;
          family: $f-raleway-medium;
        }
        line-height: 2rem;
        text-transform: uppercase;
      }

      .service-text {
        font-size: 1rem;
        margin: 1rem 0;
        line-height: 1.5rem;
        font-family: $f-quicksand-regular;
      }

      .service-price {
        font-size: 1rem;
        font-family: $f-quicksand-bold;
        line-height: 1.5rem;
      }

      .button {
        width: 270px;
        font-family: $f-raleway-medium;
        font-size: 1.1rem;
        color: $c-white;
        background-color: $c-corail;
        border: solid 1px $c-corail;
        border-radius: 50px;
        margin: 2rem auto;
        padding: 1rem 2rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: transparent;
          color: $c-corail;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .services {
    .service {
      .service-description {
        .button {
          width: 280px;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .services {
    padding: 2rem 1rem;
    background-color: $c-cream;

    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
      margin: 2rem 0;
    }

    .service {
      width: 80%;
      padding: 1rem 0;
      background-color: transparent;

      .service-image {
        margin-top: 1rem;
        width: 350px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .services {
    .service {
      flex-direction: row;
      justify-content: space-between;

      .service-description {
        .service-text {
          width: 90%;
        }

        .button {
          margin: 2rem 0;
        }
      }
    }

    .service:nth-child(even) {
      flex-direction: row-reverse;

      .service-description {
        .service-text {
          width: 85%;
        }
        margin-left: 2rem;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .services {
    padding: 3rem 1rem;
    background-image: url('../../assets/images/SVG/HandOnShoulderBeige1199.svg'),
      url('../../assets/images/SVG/HandOnWaistBeige1199.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      width: 30%;
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    .service {
      width: 85%;

      .service-description {
        .service-title {
          font-family: $f-raleway-regular;
          font-size: 1.9rem;
          line-height: 2.9rem;
        }

        .service-text {
          font-size: 1.2rem;
          line-height: 1.7rem;
          margin: 1rem 0;
        }

        .service-price {
          font-size: 1.2rem;
          line-height: 1.3rem;
        }

        .button {
          width: 330px;
          font-size: 1.3rem;
          margin: 3rem 0;
          padding: 1.2rem 0;
        }
      }

      .service-image {
        margin-top: 1rem;
        width: 500px;
      }
    }
  }
}

@media (min-width: 1399px) {
  .services {
    background-image: url('../../assets/images/SVG/HandOnShoulderBeige1399.svg'),
      url('../../assets/images/SVG/HandOnWaistBeige1399.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }

    .service {
      width: 70%;

      .service-description {
        .service-title {
          font-size: 2.5rem;
          line-height: 3.5rem;
        }
      }
    }
  }
}

@media (min-width: 1599px) {
  .services {
    .title {
      font-size: 4rem;
      line-height: 5rem;
    }

    .service {
      .service-description {
        .service-title {
          font-size: 3rem;
          line-height: 4rem;
        }

        .service-text {
          font-size: 1.3rem;
          line-height: 1.8rem;
        }

        .service-price {
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
      }

      .service-image {
        width: 650px;
      }
    }

    .button {
      font-size: 1.4rem;
    }
  }
}
