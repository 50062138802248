.legales-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
  background-color: $c-cream;
  border-bottom: $c-corail 1px solid;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-medium;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-brown;
    margin: 2rem 0;
  }

  .legales-text {
    width: 80%;
    height: 100px;
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
    margin: 2rem 0;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .legales-container {
    padding: 4rem 1rem 1rem 1rem;

    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
      margin: 2rem 0 0 0;
    }
  }
}

@media (min-width: 1024px) {
  .legales-container {
    padding: 5rem 1rem 1rem 1rem;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .legales-container {
    justify-content: space-around;
    padding: 6rem 1rem 1rem 1rem;

    .title {
      width: 30%;
      font-size: 2.5rem;
      line-height: 3.5rem;
      margin: 2rem 0;
    }

    .legales-text {
      height: 300px;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}

@media (min-width: 1399px) {
  .legales-container {
    padding: 10rem 1rem 1rem 1rem;

    .title {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
}

@media (min-width: 1599px) {
  .legales-container {
    .title {
      font-size: 3rem;
      line-height: 4rem;
    }

    .legales-text {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
}

@media (min-width: 1920px) {
}
