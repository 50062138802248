.header-container {
  width: 100vw;
  height: 0px;
  transform: translateY(-100px);
  transition: transform ease-in-out 0.5s;
  z-index: 9999;
  background-color: $c-beige;

  .navigation {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-imageCtn {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .icons-container {
      display: flex;
      margin: 0.5rem auto;

      a {
        width: 30px;
        height: 30px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: translateY(-3px);
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      *:not(:last-child) {
        margin-right: 0.2rem;
      }
    }

    #idCheckbox {
      display: none;
    }

    #burger {
      width: 35px;
      height: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      background-color: transparent;

      .burger {
        height: 2px;
        background-color: $c-black;
        transition: 0.5s;
        z-index: 999;
      }

      .top {
        width: 35px;
      }

      .middle {
        width: 25px;
      }

      .bottom {
        width: 15px;
      }
    }

    #idCheckbox:checked ~ #burger > div:nth-child(1) {
      transform: translateY(8.5px) rotate(45deg);
    }

    #idCheckbox:checked ~ #burger > div:nth-child(2) {
      scale: 0;
    }

    #idCheckbox:checked ~ #burger > div:nth-child(3) {
      transform: translateY(-8.5px) rotate(-45deg);
      width: 35px;
    }
  }

  .nav-list {
    height: 0px;
    display: flex;
    transform: translateX(100%);
    z-index: -1;
    overflow-x: hidden;
  }

  .nav-list-active {
    width: 100%;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $c-beige;
    position: fixed;
    top: 48px;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 2.5rem;
    transition: transform ease-in-out 0.7s;
    z-index: 999999999;
    overflow-x: hidden;

    .nav-item {
      width: auto;
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      font-family: $f-quicksand-semibold;

      &:nth-child(n):not(:last-child)::after {
        content: '';
        width: 150px;
        height: 0.5px;
        position: absolute;
        top: 2.5rem;
        background-color: $c-black;
      }
    }
  }
}

.header-container-fixed {
  height: 50px;
  position: fixed;
  transform: translateY(0px);
  transition: transform ease-in-out 0.5s;
  box-shadow: 0px 0px 10px $c-black;

  .navigation {
    height: 100%;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .header-container {
    .navigation {
      .logo-container {
        .logo-imageCtn {
          width: 50px;
          height: 50px;
        }
      }

      .icons-container {
        a {
          width: 35px;
          height: 35px;
        }
      }

      #burger {
        width: 40px;
        height: 1.5rem;

        .top {
          width: 40px;
        }

        .middle {
          width: 30px;
        }

        .bottom {
          width: 20px;
        }
      }

      #idCheckbox:checked ~ #burger > div:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
      }

      #idCheckbox:checked ~ #burger > div:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
        width: 40px;
      }
    }

    .nav-list-active {
      height: calc(100vh - 60px);
      top: 60px;
      line-height: 3rem;

      .nav-item {
        &:nth-child(n):not(:last-child)::after {
          top: 3rem;
        }
      }
    }
  }

  .header-container-fixed {
    height: 60px;
  }
}

@media (min-width: 899px) {
  .header-container-fixed {
    height: 60px;
  }
}

@media (min-width: 1024px) {
  .header-container {
    .navigation {
      .logo-container {
        .logo-imageCtn {
          width: 60px;
          height: 60px;
        }
      }

      .icons-container {
        a {
          width: 40px;
          height: 40px;
        }
      }

      #burger {
        width: 50px;
        height: 1.5rem;

        .top {
          width: 50px;
        }

        .middle {
          width: 40px;
        }

        .bottom {
          width: 30px;
        }
      }

      #idCheckbox:checked ~ #burger > div:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
      }

      #idCheckbox:checked ~ #burger > div:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
        width: 50px;
      }
    }

    .nav-list-active {
      height: calc(100vh - 70px);
      top: 70px;
      line-height: 4rem;
      font-size: 1.2rem;

      .nav-item {
        &:nth-child(n):not(:last-child)::after {
          width: 250px;
          top: 4rem;
        }
      }
    }
  }

  .header-container-fixed {
    height: 70px;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1200px) {
  .header-desktop-container {
    width: 100vw;
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: $c-cream;
    position: fixed;
    transition: all 0.5s ease-in;
    z-index: 9999;

    .icons-container {
      width: 150px;
      display: flex;

      .icon {
        width: 35px;
        transition: all 0.3s ease-in-out;
        fill: $c-corail;

        &:hover {
          transform: translateY(-3px);
        }
      }

      *:not(:last-child) {
        margin-right: 0.2rem;
      }
    }

    .navigation {
      display: flex;

      .nav-list-desktop {
        width: 350px;
        display: flex;
        align-items: center;
        font-family: $f-quicksand-medium;
        font-size: 1rem;
        color: $c-brown;

        .nav-item {
          position: relative;

          &::after {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -0.2rem;
            background-color: $c-brown;
            scale: 0;
            transition: all ease-in-out 0.3s;
          }

          &:hover::after {
            scale: 1;
          }
        }

        *:not(:last-child) {
          margin-right: 1rem;
        }
      }

      .left {
        justify-content: flex-end;
      }

      .right {
        justify-content: flex-start;
      }

      .logo-container {
        margin: 0 1rem;
      }
    }

    .button-container {
      width: 150px;
      display: flex;
      justify-content: flex-end;

      .button {
        font: {
          size: 1.1rem;
          family: $f-raleway-medium;
        }
        color: $c-cream;
        background-color: $c-corail;
        border: solid 1px $c-corail;
        border-radius: 50px;
        padding: 1rem 2rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $c-corail;
          background-color: $c-cream;
          border: solid 1px $c-corail;
        }
      }
    }
  }

  .header-hidden {
    transform: translateY(-200px);
  }

  .header-displayed {
    transform: translateY(0px);
    box-shadow: 0px 0px 10px $c-black;
  }
}

@media (min-width: 1399px) {
  .header-desktop-container {
    .icons-container {
      width: 170px;

      .icon {
        width: 40px;
      }
    }

    .navigation {
      .nav-list-desktop {
        width: 450px;
        font-size: 1.1rem;

        *:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }

    .button-container {
      width: 170px;
    }
  }
}
