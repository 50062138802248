.reviews {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $c-cream;
  padding: 1rem 0 4rem 0;
  overflow-y: hidden !important;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-regular;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: darken($c-beige, 10%);
    margin: 2rem 0;
  }

  .all-reviews-button {
    background-color: #4888f4;
    color: $c-white;
    font-family: $f-quicksand-semibold;
    padding: 0.7rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    margin-top: 4rem;

    &:hover {
      background-color: #1369fd;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .reviews {
    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
    }

    .button {
      margin: 3rem 0 1rem 0;
    }
  }
}

@media (min-width: 1024px) {
  .reviews {
    .button {
      margin: 1rem 0;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .reviews {
    background-image: url('../../assets/images/SVG/HandOnShoulderBeige1199.svg'),
      url('../../assets/images/SVG/HandOnWaistBeige1199.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    .button {
      margin: 1rem 0 2rem 0;
      font-size: 1.3rem;
      padding: 1.2rem 3.5rem;
    }
  }
}

@media (min-width: 1399px) {
  .reviews {
    background-image: url('../../assets/images/SVG/HandOnShoulderBeige1399.svg'),
      url('../../assets/images/SVG/HandOnWaistBeige1399.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }

    iframe {
      width: 90%;
    }

    .button {
      font-size: 1.4rem;
    }
  }
}

@media (min-width: 1399px) {
  .reviews {
    .title {
      font-size: 4rem;
      line-height: 5rem;
    }
    iframe {
      width: 80%;
    }
  }
}

@media (min-width: 1399px) {
  .reviews {
    iframe {
      width: 70%;
    }
  }
}
