.gift {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: $c-corail;

  .gift-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .gift-text {
      .title {
        font: {
          size: 1.9rem;
          family: $f-raleway-regular;
        }
        line-height: 2.5rem;
        text-align: center;
        color: $c-white;
        margin: 2rem 0;
      }

      .description {
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        color: $c-white;
      }
    }

    .gift-icon {
      margin: 2rem 0 0 0;
      width: 80px !important;
      height: 80px !important;
    }
  }

  .button {
    margin: 2rem 0;
    font: {
      size: 1.1rem;
      family: $f-raleway-medium;
    }
    color: $c-corail;
    background-color: $c-white;
    border: solid 1px $c-white;
    border-radius: 50px;
    padding: 1rem 2rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: transparent;
      color: $c-white;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .gift {
    padding: 2rem 1rem;

    .gift-content {
      width: 80%;
      flex-direction: row;
      justify-content: space-between;

      .gift-text {
        .title {
          width: 80%;
          font-size: 2.2rem;
          line-height: 2.7rem;
          margin: 2rem 0;
          text-align: left;
        }

        .description {
          width: 70%;
          text-align: start;
        }
      }

      .gift-icon {
        margin-top: 8rem;
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
}

@media (min-width: 899px) {
  .gift {
    .gift-content {
      .gift-icon {
        width: 120px !important;
        height: 120px !important;
      }
    }
  }
}

@media (min-width: 1024px) {
  .gift {
    .gift-content {
      .gift-icon {
        margin-top: 5rem;
        width: 150px !important;
        height: 150px !important;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .gift {
    padding: 1rem;
    box-shadow: 0px 0px 10px $c-black;
    z-index: 10;

    .gift-content {
      width: 90%;
      .gift-text {
        .title {
          width: 100%;
          font-size: 2.5rem;
          line-height: 3.5rem;
        }

        .description {
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }

      .gift-icon {
        width: 150px !important;
        height: 150px !important;
      }
    }

    .button {
      font-size: 1.3rem;
      margin: 2rem 0;
      padding: 1.2rem 3.5rem;
    }
  }
}

@media (min-width: 1399px) {
  .gift {
    .gift-content {
      width: 75%;

      .gift-text {
        .title {
          font-size: 3rem;
          line-height: 4rem;
        }
      }

      .gift-icon {
        width: 170px !important;
        height: 170px !important;
      }
    }
  }
}

@media (min-width: 1599px) {
  .gift {
    .gift-content {
      .gift-text {
        .title {
          font-size: 3rem;
          line-height: 4rem;
        }

        .description {
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
      }
    }

    .button {
      font-size: 1.4rem;
    }
  }
}
