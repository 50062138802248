.faq {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  background-color: $c-beige;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-regular;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-black;
    margin: 2rem 0;
  }

  .faq-content {
    width: 95%;
    display: flex;
    flex-direction: column;
    line-height: 1.3rem;
    margin: 0 auto;

    .question-container {
      width: 100%;
      margin: 1rem auto;

      .question {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: $f-quicksand-semibold;
        font-size: 1rem;
        line-height: 1.5rem;
        cursor: pointer;

        p {
          width: 85%;
        }

        .question-icon {
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .question-container:not(:last-child) {
      padding-bottom: 2rem;
      border-bottom: solid 1px $c-black;
    }

    .answer-container {
      width: 100%;

      .answer {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    details {
      .question-icon {
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);
      }
    }

    details > summary {
      list-style: none;
    }

    details > summary::-webkit-details-marker {
      display: none;
    }

    details[open] {
      .question-icon {
        width: 20px;
        height: 20px;
        transform: rotate(0deg);
      }
    }

    details[open] summary {
      transition: all 0.4s ease-in-out;
      margin-bottom: 1rem;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .faq {
    padding: 2rem 1rem 3rem 1rem;

    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
    }

    .faq-content {
      width: 70%;
    }
  }
}

@media (min-width: 899px) {
  .faq {
    .faq-content {
      margin-top: 2rem;
      width: 60%;
    }
  }
}

@media (min-width: 1024px) {
  .faq {
    .faq-content {
      width: 50%;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .faq {
    padding: 3rem 1rem 4rem 1rem;
    background-image: url('../../assets/images/SVG/HandOnShoulderCream1199.svg'),
      url('../../assets/images/SVG/HandOnWaistCream1199.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    .faq-content {
      .question-container {
        .question {
          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }

      .answer-container {
        .answer {
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
    }
  }
}

@media (min-width: 1399px) {
  .faq {
    background-image: url('../../assets/images/SVG/HandOnShoulderCream1399.svg'),
      url('../../assets/images/SVG/HandOnWaistCream1399.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}

@media (min-width: 1599px) {
  .faq {
    .title {
      font-size: 4rem;
      line-height: 5rem;
    }

    .faq-content {
      .question-container {
        .question {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      .answer-container {
        .answer {
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
      }
    }
  }
}
