.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $c-cream;
  color: $c-corail;
  font-family: $f-raleway-regular;

  .bold {
    font-family: $f-quicksand-bold;
  }

  .block {
    display: block !important;
  }

  .footer-content {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 3rem 1rem;

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contentCtn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;

        & > *:not(:last-child) {
          margin-bottom: 1rem;
        }

        .button {
          width: 220px;
          font: {
            size: 1rem;
            family: $f-raleway-medium;
          }
          color: $c-corail;
          background-color: transparent;
          border: solid 1px $c-corail;
          border-radius: 50px;
          padding: 1rem 0;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $c-corail;
            border: solid 1px $c-corail;
            color: $c-cream;
          }
        }

        .footer-icons {
          display: flex;
          justify-content: center;

          .icon:hover {
            transform: translateY(-3px);
          }

          .icon {
            transition: all 0.3s ease-in-out;
          }

          & > *:not(:last-child) {
            margin-right: 0.2rem;
          }
        }

        .footer-mentions {
          text-align: center;
          position: relative;

          &::after {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -0.2rem;
            background-color: $c-corail;
            scale: 0;
            transition: all ease-in-out 0.3s;
          }

          &:hover::after {
            scale: 1;
          }
        }
      }
    }

    .contact {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 2rem 0;

      .footer-infos {
        font-family: $f-quicksand-regular;
        font-size: 1rem;
        line-height: 1.5rem;

        & > *:not(:last-child) {
          display: inline-block;
        }
      }
    }

    .logo-copyright {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;

        .logo {
          width: 100px;
          height: 100px;
          margin-bottom: 1rem;
        }
      }

      .copyright {
        font-size: 1rem;
      }
    }

    .legs {
      width: 300px;
      height: 150px;
      position: absolute;
      left: -2rem;
      bottom: 0;
    }
  }

  .credit {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: $c-corail solid 1px;
    font-size: 1rem;

    .fidia {
      text-decoration: underline;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .footer-container {
    .footer-content {
      height: 350px;
      flex-direction: row;
      padding: 4rem 2rem;

      .content {
        width: 30%;
        align-self: start;
        align-items: flex-start;

        .contentCtn {
          .button {
            width: 300px;
            font-size: 1.1rem;
            margin-top: 2rem;
          }

          .footer-mentions {
            font-size: 1.1rem;
          }
        }
      }

      .contact {
        width: 30%;

        .footer-infos {
          font-size: 1.1rem;
        }

        .footer-infos > *:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }

      .logo-copyright {
        width: 30%;
        align-items: flex-end;

        .logo-container {
          .logo {
            width: 150px;
            height: 150px;
          }
        }

        .copyright {
          font-size: 1.1rem;
        }
      }

      .legs {
        width: 350px;
        height: 200px;
        position: absolute;
        left: -2rem;
        bottom: 0;
      }
    }

    .credit {
      width: 100%;
      font-size: 1.1rem;
    }
  }
}
