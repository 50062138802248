.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: $c-brown;

  .title {
    font: {
      size: 1.9rem;
      family: $f-raleway-regular;
    }
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    color: $c-black;
    margin: 2rem 0;
  }

  .about-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .about-text {
      font-size: 1rem;
      line-height: 1.5rem;

      .span {
        display: block;
        font-style: italic;
        margin-top: 1rem;
      }
    }

    .about-image {
      width: 300px;
      margin: 2rem 0;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .about {
    padding: 2rem 1rem;

    .title {
      width: 80%;
      font-size: 2.2rem;
      line-height: 2.7rem;
    }

    .about-content {
      .about-text {
        width: 80%;
      }

      .about-image {
        width: 350px;
      }
    }
  }
}

@media (min-width: 899px) {
  .about {
    .about-content {
      .about-text {
        width: 70%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .about {
    .about-content {
      width: 70%;
      flex-direction: row-reverse;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .about {
    padding: 3rem 1rem;
    background-image: url('../../assets/images/SVG/FaceWithHandsBlack1199.svg'),
      url('../../assets/images/SVG/HandOnShoulderBlack1199.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      width: 30%;
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
    .about-content {
      width: 70%;
      display: flex;

      .about-text {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }

      .about-image {
        width: 450px;
      }
    }
  }
}

@media (min-width: 1399px) {
  .about {
    background-image: url('../../assets/images/SVG/FaceWithHandsBlack1399.svg'),
      url('../../assets/images/SVG/HandOnShoulderBlack1399.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}

@media (min-width: 1599px) {
  .about {
    .title {
      font-size: 4rem;
      line-height: 5rem;
    }
    .about-content {
      width: 60%;

      .about-text {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }

      .about-image {
        width: 500px;
      }
    }
  }
}
